import { RouteRecordRaw } from 'vue-router';

import { Auth } from '$common/modules/auth/middelwares.ts';
import { routes as authRoutes } from '$common/modules/auth/routes.ts';
import { routes as profileRoutes } from '$common/modules/profile/routes.ts';

export const routeModuleLoader = () =>
  Object.values(import.meta.glob('../modules/**/routes.ts', { eager: true })).flatMap(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (m: any) => m?.routes ?? m?.default ?? [],
  );

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('$central/layouts/WebsiteLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('$central/views/LandingPage.vue'),
        name: 'root',
      },
    ],
  },

  {
    path: '/backstage',
    children: [
      ...authRoutes,

      {
        path: '/profile',
        meta: { middlewares: [Auth] },
        component: () => import('$central/layouts/DefaultLayout.vue'),
        children: profileRoutes,
      },

      {
        path: '',
        meta: { middlewares: Auth },
        component: () => import('$central/layouts/DefaultLayout.vue'),
        children: [
          ...routeModuleLoader(),
          {
            path: '',
            name: 'dashboard',
            component: () => import('$central/views/IndexPage.vue'),
          },
        ],
      },

      // always last
      {
        path: ':catchAll(.*)*',
        meta: { middlewares: Auth },
        component: () => import('$central/layouts/DefaultLayout.vue'),
        children: [
          {
            path: '',
            component: () => import('$central/views/404Page.vue'),
          },
        ],
      },
    ],
  },

  // always last
  {
    path: '/:catchAll(.*)*',
    component: () => import('$central/layouts/WebsiteLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('$central/views/404Page.vue'),
      },
    ],
  },
];
